*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-family: Facundo, sans-serif;
  font-size: 16px;

  --hovergray: #FAFAF8;
  --panel-blue: #edf6f6;
  --hover-bg-saturated: #dff0f1;
  --hover-border-saturated: #cae6eb;

  --dark-indigo: #091a31;
  --dusk-blue: #243f8e;
  --flat-green: #6ba049;
  --dark-tan: #af9042;
  --white: #ffffff;
  --off-white: #f0f0e9;
  --bluish: #237db0;
  --light-blue: #98d1ec;
  --bluegrey: #6abbd4;
  --pastel-red: #d45958;
  --light-peach: #d9d3cc;
  --light-blue-grey: #c7c9cb;
  --bluey-grey: #96999c;
  --slate-grey: #5a5b5e;
  --grey-blue: #609fb2;
  --dark-slate-blue: #182860;
  --pale-sky-blue: #c8e4e7;
  --cloudy-blue: #b5d2d7;
  --soft-blue: #7575d5;
  --ice-blue: #e1f3f5;
  --slate: #556780;

  --cubicIn: cubic-bezier(0.550, 0.055, 0.675, 0.190);
  --cubicOut: cubic-bezier(0.215, 0.610, 0.355, 1.000);

  --spacing: 24px;
  --desktop-width: 1440px;
  --hover-border: 12px solid #DAEEF5;
  --drop-shadow: 0 -10px 10px 10px;
}

img {
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

strong {
  font-weight: 900;
}

.trade {
  display: inline !important;
  font-weight: normal;
  vertical-align: top;
  font-size: 0.7em;
}

button, .button {
  display: grid;
  align-content: center;
  width: max-content;
  margin: 1rem 0;

  &.small {
    height: var(--spacing);
    border-radius: calc(var(--spacing) / 2);
    font-size: 14px;
    font-weight: 400;
    padding: 0 var(--spacing);
  }

  &.large {
    height: calc(var(--spacing) * 2);
    border-radius: var(--spacing);
    font-size: 20px;
    font-weight: 600;
    padding: 0 calc(var(--spacing) * 2);
  }

  &.solid {
    color: white;
    background-color: var(--dusk-blue);
    transition: background-color 0.25s var(--cubicIn);

    &:hover, :active, :focus {
      background-color: var(--bluish);
      transition: background-color 0.25s var(--cubicOut);
    }
  }

  &.outline {
    color: var(--dusk-blue);
    border: 2px solid currentColor;
    transition: color 0.25s var(--cubicIn),
      border-color 0.25s var(--cubicIn);

    &:hover, :active, :focus {
      color: var(--bluish);
      border-color: var(--bluish);
      transition: color 0.25s var(--cubicOut),
        border-color 0.25s var(--cubicOut);
    }
  }

  &.round {
    border-radius: 50%;
    padding: 0;
  }
}

.topbar {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  font-size: 14px;
  color: white;
  background-image: linear-gradient(
    to right,
    #87d3eb 0%,
    #0081b4 16%,
    #0d66a6 20.5%,
    #1c4997 29%,
    #223f92 35.5%,
    #233e92 50%,
    #223f92 64.5%,
    #1c4997 71%,
    #0d66a6 79.5%,
    #0081b4 84%,
    #87d3eb 100%
  );

  p {
    width: initial;
    max-width: 1440px;
  }
}

.hashtag {
  color: var(--light-blue);
  font-weight: bold;
}

nav {
  #navbar {
    display: grid;
    position: relative;
    z-index: 5;
    padding: 0 var(--spacing);
    margin: auto;
    width: var(--desktop-width);
    grid-template-columns: repeat(10, 1fr);
    gap: var(--spacing);

    &::before, &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: white;
    }

    &::before {
      margin-left: calc(-1 * ((100vw - var(--desktop-width)) / 2));
    }

    &::after {
      margin-right: calc(-1 * ((100vw - var(--desktop-width)) / 2));
    }
  }

  &::after {
    box-shadow: var(--drop-shadow);
    content: '';
    width: 100%;
    height: 0.05px; // Required for Safari
    position: absolute;
    pointer-events: none;
    clip-path: inset(0px 0px -20px 0px);
  }
}

.logo-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.logo {
  width: 112px;
  height: auto;
  display: block;
  margin-bottom: 1rem;
  transition: 0.25s var(--cubicOut);

  &:hover {
    transform: scale(1.125);
  }
}

.dropdown-nav {
  display: flex;
  position: relative;
  grid-column: span 6;
  padding: 0;
  justify-self: center;

  li {
    padding: 4rem 2rem 1rem;
    cursor: pointer;
    position: relative;
    font-family: Facundo;
    font-weight: bold;
  }
}

.nav-hover {
  opacity: 0;
  background-color: var(--hovergray);
  position: absolute;
  top: 0;
  bottom: 0;
  transition: left 0.55s,
    width 0.55s,
    opacity 0.5s;

  .dropdown-nav:hover & {
    opacity: 1;
  }
}

.nav-arrow {
  position: absolute;
  width: 30px;
  height: 15px;
  overflow: hidden;
  bottom: -15px;
  transform: translateX(-15px);
  transition: left 0.55s;
  pointer-events: none;

  &::after {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    left: 50%;
    transform: translateX(-10px) translateY(-25px) rotate(45deg);
    transform-origin: center;
    background-color: #233e92;
    background-image: linear-gradient(
      135deg,
      #233e92 17.4%,
      #223f92 40.7%,
      #1c4997 48.1%,
      #0d66a6 61.0%,
      #0081b4 70.7%,
      #87d3eb 94.7%
    );
    transition: transform 0.5s;
    
    .dropdown-nav:hover & {
      transform: translateX(-10px) translateY(-10px) rotate(45deg);
    }
  }
}

#nav-drawer {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease-in-out;
  background-color: var(--hovergray);

  &.opened {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }

  .drawer-container {
    position: absolute;
    padding: 0 var(--spacing);
    width: var(--desktop-width);
    min-height: 100%;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    column-gap: var(--spacing);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    pointer-events: none;
  }

  .active-drawer {
    opacity: 1;
    pointer-events: all;
  }

  button {
    position: absolute;
    top: var(--spacing);
    right: var(--spacing);
    width: 32px;
    height: 32px;
    outline: none;
    border: 0;
    background: transparent;
    margin: 0;
  }

  &::after {
    box-shadow: var(--drop-shadow);
    content: "";
    width: 100%;
    height: 0.05px; // Required for Safari
    position: absolute;
    pointer-events: none;
    clip-path: inset(0px 0px -20px 0px);
    bottom: 0;
  }
}

.products-list {
  grid-column: 2 / span 3;
  margin: calc(var(--spacing) * 2.25) 0;
  --hover-y-position: calc(var(--spacing) *2.25);
  --hover-height: 50px;

  li {
    a {
      display: block;
      padding: 1rem 0;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      transition: text-indent 0.5s;

      &:hover {
        text-indent: var(--spacing);
      }

      small {
        display: block;
        font-size: 10px;
        font-weight: normal;
        line-height: 1.2;
      }
    }

    ul {
      margin: calc(var(--spacing) / 4) 0;

      li {
        font-size: 18px;
        border-left: 12px solid transparent;
        padding: 0.25em 0 0.25rem 0.5rem;
        width: calc(100% + var(--spacing));
        transition: all 0.5s;
        cursor: pointer;
        margin-bottom: 1em;

        &:hover {
          text-indent: var(--spacing);
          border-left: var(--hover-border);
          background: var(--panel-blue);
        }
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: var(--hover-y-position);
    left: 0;
    width: 100%;
    height: var(--hover-height);
    background-color: var(--panel-blue);
    border-left: var(--hover-border);
    transition: all 0.5s;
  }
}

.products-content {
  grid-column: span 6;
}

.panel {
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  padding: calc(var(--spacing) * 2);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: var(--spacing);
  align-items: stretch;
  transition: all 0.25s;
  opacity: 0;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-right: calc(-1 * ((100vw - var(--desktop-width)) / 2));
    background-color: var(--panel-blue);
  }

  div {
    &:first-of-type {
      grid-column: span 4;

      h3 {
        margin-bottom: calc(var(--spacing) / 3);
      }

      h6  {
        margin-bottom: var(--spacing);
      }

      p {
        line-height: 1.5;
      }

      .button {
        margin-top: var(--spacing);
      }
    }
  }

  .placeholder {
    grid-column: span 2;
    border: 1px solid #c4caca;
    width: 100%;
    height: 50%;
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.25s;
  }
}

.nav-right {
  grid-column: span 3;

  ul {
    display: grid;
    align-content: space-around;
    justify-content: space-between;
    grid-template-areas: 
    'developer support login search'
    '. . . download';
    height: 100%;

    li {
      font-size: 14px;

      &:nth-child(1) {
        grid-area: developer;
      }

      &:nth-child(2) {
        grid-area:support;
      }

      &:nth-child(3) {
        grid-area:login;
      }

      &:nth-child(4) {
        grid-area:search;
      }

      &:nth-child(5) {
        grid-area:download;
        grid-column: span 4;
        margin: auto 0 auto auto;

        .button {
          margin: 0;
        }
      }

      a {
        vertical-align: sub;

        img {
          vertical-align: text-bottom;
          margin-right: 0.5rem;
          width: 20px;
          height: auto;
        }
      }
    }
  }
}

h3 {
  font-size: 48px;
  font-weight: bold;
}

.solutions-list {
  font-size: 18px;
  line-height: 1.5;
  position: relative;
  margin-top: calc(var(--spacing));
  column-count: 2;
  column-gap: var(--spacing);

  --hover-left: 0;
  --hover-top: 0;
  --hover-height: 0;
  --hover-width: 0;
  --opacity: 0;

  a {
    display: block;
    padding-top: 4.5px;
    padding-bottom: 4.5px;
    transform: translateX(0);
    transition: transform 0.5s;

    &:hover {
      transform: translateX(var(--spacing));
      padding-left: var(--spacing);
      margin-left: calc(-1 * var(--spacing));
      font-weight: 600;
    }
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    background-color: var(--ice-blue);
    position: absolute;
    left: var(--hover-left);
    top: var(--hover-top);
    height: var(--hover-height);
    width: var(--hover-width);
    opacity: var(--opacity);
    border-left: var(--hover-border);
    transition: left 0.25s, top 0.25s, height 0.25s, width 0.25s, opacity 0.5s;
  }
}

.use-cases {
  grid-column: 2 / span 5;
  border-right: 2px dotted #707070;
  margin: calc(var(--spacing) * 2.25) 0;
}

.industries {
  grid-column: 7 / span 4;
  margin: calc(var(--spacing) * 2.25) 0;
}

#customers-drawer {
  div {
    p {
      margin: var(--spacing) 0;
      line-height: 1.5;
    }
  }

  div:first-of-type {
    grid-column: 2 / span 4;
    margin: calc(var(--spacing) * 2.25) 0;
  }

  div:last-of-type {
    grid-column: span 5;
    padding: calc(var(--spacing) * 2);
    top: 0;
    right: 0;
    bottom: 0;

    .testimonial {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      margin: 0;
      padding: 0;

      .image {
        display: inline-block;
        margin-right: var(--spacing);
        padding: 0;
        width: 147px;
        height: 161px;
        border-radius: 10px;
        background: lightgrey;
      }

      blockquote {
        footer {
          font-weight: 900;
        }
      }
    }

    &::after{
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: calc(50% + calc(var(--spacing) / 2));
      margin-right: calc(-1 * ((100vw - var(--desktop-width)) / 2));
      background-color: var(--panel-blue);
    }
  }

}

#learn-drawer, #company-drawer {
  --hover-left: 0;
  --hover-top: 0;
  --hover-height: 0;
  --hover-width: 0;
  --opacity: 0;

  .learn-left, .company-left {
    grid-column: 2 / span 4;
    column-count: 2;
    column-gap: var(--spacing);
    margin: calc(var(--spacing) * 2.25) 0;

    .hover-link {
      display: block;
      font-size: 18px;
      font-weight: bold;
      padding: 12px 0;
      line-height: 1.33;
      break-inside: avoid;
      transform: translateX(0);
      transition: transform 0.5s;

      &:hover {
        transform: translateX(var(--spacing));
      }

      span {
        font-weight: normal;
        display: block;
        font-size: 16px;
      }
    }

    &::before {
      content: '';
      z-index: 0;
      background-color: var(--panel-blue);
      position: absolute;
      left: var(--hover-left);
      top: var(--hover-top);
      height: var(--hover-height);
      width: var(--hover-width);
      opacity: var(--opacity);
      border-left: var(--hover-border);
      pointer-events: none;
      transition: all 0.5s;
    }
  }

  &.saturated {
    .learn-left, .company-left {
      &::before {
        background-color: var(--hover-bg-saturated);
        border-color: var(--hover-border-saturated);
      }
    }
  }

  .learn-right, .company-right {
    margin: calc(var(--spacing) * 2);
    grid-column: 6 / span 5;
    top: 0;
    right: 0;
    bottom: 0;

    ul {
      width: max-content;
      margin-top: var(--spacing);

      li {  
        a {
          display: block;
          padding: calc(var(--spacing) / 2) 0;
          transition: text-indent 0.5s, bold 0.5s;
          position: relative;
          z-index: 1;

          &:hover {
            text-indent: var(--spacing);
            font-weight: bold;
          }
        }
      }
    }
  
    p {
      margin: var(--spacing) 0;
      line-height: 1.5;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: calc(50% + calc(var(--spacing) / 2));
      margin-right: calc(-1 * ((100vw - var(--desktop-width)) / 2));
      background-color: var(--panel-blue);
    }
  }
}


.hero {
  div {
    display: flex;
    margin: auto;
    padding: 0 var(--spacing);
    width: var(--desktop-width);
    height: 600px;
    justify-content: center;
    flex-direction: column;
  }
}
